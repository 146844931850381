
import {
  DxTreeList,
  DxEditing,
  DxColumn,

  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection
} from 'devextreme-vue/tree-list';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';

import OrdenTrabajoServicioService from '../services/ordentrabajoservicio-service';


export default {
  components: {
    DxTreeList,
    DxEditing,
    DxColumn,

    DxPaging,

    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
  }, props: {
    supplierId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      equipos: null,
      product: {},
      bindingCodigo: null,
      expandedRowKeys: [1],
      dataSourceEquipments: null,

      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Equipo',
        showTitle: true,
        width: 900,
      },

    };
  },
  created() {
    this.equipmentService = new OrdenTrabajoServicioService();

  },
  mounted() {

    let id = this.supplierId;
    let myJSON = JSON.stringify({
      pguiaevaluacionid: id
    });
    this.equipmentService.getOrdenTrabajoServicioGuia(myJSON).then(data => this.dataSourceEquipments = data);

  },
  methods: {
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editEquipment(e) {
      this.equipmentService.updateEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
      });
    },
    deleteEquipment(e) {
      this.equipmentService.deleteEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
      });
    },
    createEquipment(e) {
      this.equipmentService.createEquipo(e.data).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
      });
    }
  },
};
