import axios from 'axios';

export default class OrdenTrabajoServicioService {

    getOrdenTrabajoServicio(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoservicio`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
    getOrdenTrabajoServicioGuia(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/ordentrabajoservicioguia`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
   
}